import React, {useEffect} from 'react';
import {Route, Routes, Navigate,} from "react-router-dom";
import Characteristic from "../components/Page/Characteristic/Characteristic";
import Login from "../components/Page/Login/Login";
import LocalStorage from "../components/utils/LocalStorage";
import {useDispatch, useSelector} from "react-redux";
import {isLogged, saveCurrentUser} from "../redux/reducer/userReducer";
import NotFound from "../components/Page/NotFound/NotFound";
import Forbidden from "../components/Page/Forbidden/Forbidden";
import ThematicManagement from "../components/Page/Admin/ThematicManagement/ThematicManagement";
import ThematicCreation from "../components/Page/Admin/ThematicCreation/ThematicCreation";

const Navigation = () => {
    const ls = new LocalStorage();
    const dispatch = useDispatch();

    const isLoggedIn  = useSelector(state => state.userReducer.isLogged);
    const stalUser  = useSelector(state => state.userReducer.user);
    const isLeadCoord = stalUser.lead?.name === 'Coordination';

    useEffect(() => {

        if(ls.get("STAL_USER")){
            dispatch(isLogged(true));
            dispatch(saveCurrentUser(ls.get("STAL_USER")));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Routes>
            {!isLoggedIn &&
                    <Route exact path={"/login"} element={<Login />} />
            }

            {isLoggedIn &&
                <>
                    { isLeadCoord &&
                        <>
                            <Route exact path={"/thematic-management"} element={<ThematicManagement />} />
                            <Route exact path={"/thematic-creation"} element={<ThematicCreation />} />
                            <Route exact path={"/thematic/:id"} element={<ThematicCreation />} />
                        </>
                    }
                    <Route path={'/thematic-management'} element={<Navigate to='/forbidden' />} />
                    <Route exact path={"/thematic/:id"} element={<Navigate to='/forbidden' />} />
                    <Route path={'/thematic-creation'} element={<Navigate to='/forbidden' />} />
                    <Route exact path={"/generate"} element={<Characteristic />} />
                </>
            }
            <Route path={'/'} element={ isLoggedIn ? <Navigate to='/generate' /> : <Navigate to='/login' />} />
            <Route path={'/login'} element={ isLoggedIn && <Navigate to='/generate' />} />
            <Route path={'/generate'} element={ !isLoggedIn ? <Navigate to='/login' /> : <Navigate to='/generate' />} />
            <Route exact path={"/forbidden"} element={<Forbidden /> } />
            <Route path={'*'} element={ <NotFound />} />
        </Routes>
    );
};

export default Navigation;
