import React from 'react';
import { Link } from "react-router-dom";
import {useSelector} from "react-redux";

const NotFound = () => {

    const isLoggedIn  = useSelector(state => state.userReducer.isLogged);

    return (
        <section className="page_404">
            <div className="container">
                <div className=" text-center">
                    <div className="four_zero_four_bg">
                        <h1 className="text-center ">404</h1>
                    </div>
                    <div className="contant_box_404">
                        <h3 className="h2">
                            Il semblerait que vous soyez perdu ...
                        </h3>

                        <p>La page recherchée n'est pas valable</p>

                        {isLoggedIn
                            ? <Link to="/generate" className="btn btn-success">Retour vers la génération de caractéristiques  </Link>
                            : <Link to="/login" className="btn btn-success">Retour à l'accueil </Link>
                        }
                    </div>
                </div>
            </div>
        </section>
    );
};

export default NotFound;
