import React, {useState} from "react";
import {GOOGLE_CONF} from "../../../lib/GoogleLogin";
import GoogleLogin from "react-google-login";
import {TokensApi} from 'stalactite-auth-client'
import {UsersMeApi} from 'stalactite-data-client'
import LocalStorage from "../../utils/LocalStorage";
import {isLogged, saveCurrentUser} from "../../../redux/reducer/userReducer";
import {useDispatch} from "react-redux";
import AuthenticationError from "../../../errors/AuthenticationError";
import StalactiteError from "../../../errors/StalactiteError";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import {setErrorMessage, setIsActive} from "../../../redux/reducer/errorReducer";

const Login = () => {

    const ls = new LocalStorage();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const errorMessage = 'Une erreur est survenue lors de la connexion';

    const [isLoggedIn, setIsLoggedIn] = useState(false);

    async function onGoogleSignIn(googleUser) {

        let googleResponse = googleUser.getAuthResponse()
        let googleIdToken = googleResponse.id_token;
        let googleAccessToken = googleResponse.access_token;

        let res;
        try {
            res = await signInWithStalactite(googleIdToken);
        } catch(Error) {
            dispatch(setIsActive(true));
            dispatch(setErrorMessage(errorMessage));
            throw new StalactiteError(Error);
        }

        ls.add("GOOGLE_ACCESS_TOKEN", googleAccessToken, false);
        await logUser(res);
    }

    async function signInWithStalactite(googleToken) {

        const data = {
            token: googleToken,
            app: 'sfhelper'
        }

        const API = new TokensApi();

        let res;
        try {
             res = await API.login(data);
        } catch (err) {
            dispatch(setIsActive(true));
            dispatch(setErrorMessage(errorMessage));
            throw new StalactiteError(err);
        }

        return res.data.token
    }

    const logUser = async(stalToken) => {

        const API = new UsersMeApi({apiKey: stalToken});

        let user;
        let post;
        let lead;

        try {
            user = await API.getMeAsUser();
            post = await API.getMyPosts();
            lead = await API.getMyLeads();
        } catch (e) {
            dispatch(setIsActive(true));
            dispatch(setErrorMessage('Une erreur est survenu lors de la récupération de l\'utilisateur'));
            throw new AuthenticationError('Une erreur est survenu lors de la récupération de l\'utilisateur');
        }

        const jwt = jwt_decode(stalToken);
        const userPost = {...user.data, post: {...post.data[0]}, lead : {...lead.data[0]}, exp: jwt.exp};

        setIsLoggedIn(true);

        ls.add('STAL_USER', userPost);
        ls.add('STAL_TOKEN', stalToken, false );

        dispatch(saveCurrentUser(userPost));
        dispatch(isLogged(true));

        navigate("/generate");
    }

    return (
        <>
            {!isLoggedIn &&
                <div className="text-center mb-4 pt-5">
                    <h1 className="h3 mb-3 font-weight-normal">Bienvenue sur SfHelper</h1>
                    <GoogleLogin
                        clientId={GOOGLE_CONF.googleClientId}
                        buttonText="Connexion"
                        onSuccess={onGoogleSignIn}
                        cookiePolicy={'single_host_origin'}
                        isSignedIn={true}
                    />
                </div>
            }
        </>
    );
}

export default Login;
