import React from "react";
import CharacteristicTable from "./CharacteristicTable";

const Characteristic = () => {

    return (
        <div className='pt-5'>
            <div className="container">
                <CharacteristicTable />
            </div>
        </div>
    );
}

export default Characteristic;
