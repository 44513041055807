export default class LocalStorage {

    constructor() {
        this.localStorage = window.localStorage;
    }

    add(key, data, stringify = true) {

        if (!stringify){
            return  this.localStorage.setItem(key, data);
        }

        return this.localStorage.setItem(key, JSON.stringify(data));
    }

    get(key, stringify = true) {

        if (!stringify){
            return  this.localStorage.getItem(key);
        }

        return JSON.parse(this.localStorage.getItem(key));
    }

    remove(key) {
        this.localStorage.removeItem(key);
    }

    clear(){
        this.localStorage.clear();
    }

    clearDatas(){
        const googleAccessToken = this.get('GOOGLE_ACCESS_TOKEN', false);
        const stalUser = this.get('STAL_USER');
        const stalToken = this.get('STAL_TOKEN', false);
        const categoryInfo = this.get('CATEGORY_INFO');

        this.clear();

        this.add("GOOGLE_ACCESS_TOKEN", googleAccessToken, false);
        this.add("STAL_USER", stalUser);
        this.add("STAL_TOKEN", stalToken, false);
        this.add("CATEGORY_INFO", categoryInfo);
    }
}
