import { createSlice } from '@reduxjs/toolkit'

const errorReducer = createSlice({
    name: 'error',
    initialState: {
        errorMessage: '',
        isActive: false,
    },
    reducers: {
        setErrorMessage: (state, action) => {
            state.errorMessage = action.payload;
        },

        setIsActive : (state, action) => {
            state.isActive = action.payload;
        }
    },
})

export const { setErrorMessage, setIsActive } = errorReducer.actions
export default errorReducer.reducer;
