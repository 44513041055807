import React from "react";

const Select = ({name, id, datas, onChangeSetData, value }) => {

    return (
        <>
            <select
                name={name}
                id={id}
                className="form-select"
                onChange={onChangeSetData}
                value={value}
            >

                {datas.map((data,i) => (
                    <option key={i} value={data.value}>{data.name}</option>
                ))}

            </select>
        </>
    );
}

export default Select;
