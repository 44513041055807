import { createSlice } from '@reduxjs/toolkit'

const userReducer = createSlice({
    name: 'user',
    initialState: {
        user: {},
        isLogged: false,
    },
    reducers: {
        saveCurrentUser: (state, action) => {
            state.user = action.payload;
        },
        removeCurrentUser: (state) => {
            state.user = {};
        },

        isLogged : (state, action) => {
            state.isLogged = action.payload;
        }
    },
})

export const { saveCurrentUser, removeCurrentUser, isLogged } = userReducer.actions
export default userReducer.reducer;
