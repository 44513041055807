import React from 'react';
import {Toast} from "react-bootstrap";
import CustomButton from "./CustomButton";

const CustomToast = ({ handleClick, setShow, show, buttonText, buttonVariant, successMessage, className =null, classNameCustomButton = null}) => {
    return (
        <>
                <CustomButton callBack={handleClick} variant={buttonVariant} text={buttonText} className={classNameCustomButton || ''}/>

            <div className="position-fixed top-0 end-0 p-3">
                <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide className={className || ''}>
                    <Toast.Header>
                        <strong className="me-auto">SF Helper</strong>
                    </Toast.Header>
                    <Toast.Body className="text-center">
                        <h6>{successMessage}</h6>
                    </Toast.Body>
                </Toast>
            </div>
        </>
    );
};

export default CustomToast;
