import React from 'react';
import {thInfos, thInfosCreation} from '../../utils/arrays';
import Th from "./Th";

const Tr = ({creation = false}) => {

    return (
        <tr style={{cursor:"pointer"}}>
            {creation
                ? <>
                    <th> </th>
                    <Th th={thInfosCreation}/>
                </>
                : <>
                    <th> </th>
                    <Th th={thInfos}/>
                </>

            }
        </tr>
    );
};

export default Tr;
