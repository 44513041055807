import {API_URL} from './config';
import LocalStorage from "./components/utils/LocalStorage";

const requestApi = async (url , method, values) => {

    const ls = new LocalStorage();
    const TOKEN = ls.get('STAL_TOKEN', false);

    const res = await fetch(`${API_URL}/${url}`, {
        headers: {
            'Content-Type': 'application/json',
            'X-API-TOKEN': TOKEN,
        },
        method: method,
        body: JSON.stringify(values)
    });

    const result = method === 'DELETE' ? res : await res.json();
    return { ...await result, status: res.status};
}

export default requestApi;
