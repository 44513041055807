import React from "react";
import {useLocation} from "react-router-dom";

const Input = ({type, id, onChangeSetData, value, className, placeholder, isNewRow }) => {

    const location = useLocation();
    return (
        <input
            type={type}
            id={id}
            className={className}
            onChange={onChangeSetData}
            autoFocus={location.pathname === '/generate' && isNewRow ? id === "name" : id === 'thematic-name'}
            value={value}
            defaultChecked={id === "generate"}
            placeholder={placeholder}
        />
    );
}

export default Input;
