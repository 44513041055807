import React from 'react';

const Th = ({th}) => {

    return (
        th.map((info, i) => (
            <th data-bs-toggle="tooltip" title={info.title} className='text-center' key={i}>
                {info.name}
            </th>
        ))
    );
};

export default Th;
