import React from 'react';
import Tr from "../Html/Tr";
import {ReactSortable} from "react-sortablejs";
import Row from "../Row";
import {Table} from "react-bootstrap";
import LocalStorage from "../../utils/LocalStorage";

const CustomTable = ({ addRow, datas, setList, isCreation, isNewRow }) => {

    const ls = new LocalStorage();
    let isSorting = false

    const removeRow = (id) => {
        const elements = datas.filter(el => el.id !== id);
        ls.remove(id);
        setList(elements);
    }

    const updateData = (row) => {

        const index = datas.findIndex((d) => d.id === row.id);

        if (index === -1) {
            return;
        }

        const copy = [...datas];
        copy[index] = row;
        setList(copy);
    }

    const updateOrder = (updatedList) => {
        if (!isCreation){
            if (!isSorting) return;
            isSorting = false;

            ls.clearDatas();
            let rowOrder = 0;

            updatedList.map(row => {
                row.id = rowOrder;
                ls.add(row.id, row);
                return rowOrder++;
            });

            setList([]);
            setList(updatedList);
        } else {
            setList(updatedList);
        }
    }

    return (
        <Table
            className="container"
            style={{ backgroundColor: 'white', marginBottom: '20px' }}
            hover
            onKeyDown={addRow}
        >
            <thead>
                <Tr creation={isCreation} />
            </thead>

            <ReactSortable
                tag="tbody"
                list={datas}
                onUpdate={() => isSorting = true}
                setList={(updatedList) => updateOrder(updatedList)}
            >
                {datas.map(row =>
                    <Row
                        key={row.id}
                        id={row.id}
                        fetchedCharacteristic={row}
                        remove={() => removeRow(row.id)}
                        getDatas={(row) => updateData(row)}
                        creation={isCreation}
                        isNewRow={isNewRow}
                    />
                )}
            </ReactSortable>

        </Table>
    );
};

export default CustomTable;
