/*Characteritic*/

export const typesDatas = [
    { name : 'Texte', value : 'varchar'},
    { name : 'Nombre', value : 'int'},
    { name : 'Prix', value : 'float'},
    { name : 'Date', value : 'datetime'},
    { name : 'Panneau', value : 'panneau'},
    { name : 'Texte long', value : 'longtext'},
    { name : 'Source', value : 'source'},
];

export const sortsDatas = [
    { name : 'Aucun', value : '0'},
    { name : 'Croissant/Décroissant', value : '1'},
    { name : 'Liste', value : '2'},
    { name : 'Filtre multiple', value : '3'},
];

export const typoDatas = [
    { name : 'Secondaire', value : '0'},
    { name : 'Primaire', value : '1'},
];

const yesOrNo = [
    { name : 'Non', value : '0'},
    { name : 'Oui', value : '1'},
];

export const backDatas = yesOrNo;

export const requiredImmoDatas = yesOrNo;

/*Category infos*/
export const numbers = [...Array(11).keys()].map(number => {
    return {name:number.toString(), value: number.toString()}
});

/*Tr */

const tr = [
    {
        name: "Nom",
        title: "Nom de la caractéristique telle qu’elle sera visible sur le FO"
    },
    {
        name: "Type",
        title: "Le type de champ sera utilisé pour déterminer les particularités d'affichage en BO (texte, date, panneau, texte long, source externe), " +
            "également pour les tris croissant / décroissant avec les types 'nombre', 'prix' et 'date' " +
            "ou les tris par liste avec le type 'texte' (ex:tri par marque de voiture)"
    },
    {
        name: "Suffixe",
        title: "Suffixe qui vient se placer après la valeur de la caractéristique. Ex: € pour un prix, m² pour une surface."
    },
    {
        name: "Tri",
        title: "Ici vous choisissez si un tri doit être appliqué à la caractristiques côté FO dans le listing des annonces. " +
            "Ex: prix -> tri croissant/décroissant, marque de voiture -> tri par liste"
    },
    {
        name: "Typologie",
        title: "Primaire: la caractéristique sera affichée dans le listing. Ex: un prix, qui sera affiché en-dessous du titre de l'annonce. " +
            "Secondaire: la caractéristique s'affichera dans la fiche technique de l'annonce. Ex: la surface de la chambre 1."
    },
    {
        name: "Requis immobilier",
        title: "Si oui, la caractéristique apparaîtra dans la fiche vitrine à imprimer de l'annonce."
    },
    {
        name: "Back",
        title: "Si oui, la caractéristique sera visible dès le listing d'annonces dans le BO."
    },
    {
        name: "Générer",
        title: "Cochée, la caractéristiques sera prise en compte pour sa génération"
    },
];

export const thInfos = tr;
export const thInfosCreation = tr.filter(el => el.name !== "Générer");

/*column to exclude*/
export const columnToExcludePanel = ['panneau', 'longtext', 'source'];
export const columnToExcludeSourceOrLongText = ['longtext', 'source'];
