import Navigation from "./Navigation/Navigation";
import {useNavigate} from "react-router-dom";
import NavBar from "./components/generalsComponents/NavBar";
import {useDispatch, useSelector} from "react-redux";
import LocalStorage from "./components/utils/LocalStorage";
import React, {useEffect} from "react";
import {isLogged, removeCurrentUser, saveCurrentUser} from "./redux/reducer/userReducer";
import Error from "./components/generalsComponents/Error";
import {setIsActive} from "./redux/reducer/errorReducer";

function App() {

    const ls = new LocalStorage();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const isLoggedIn = useSelector(state => state.userReducer.isLogged);
    const isActiveError = useSelector(state => state.errorReducer.isActive);
    const errorMessage = useSelector(state => state.errorReducer.errorMessage);

    const handleClose = () => dispatch(setIsActive(false));

    useEffect(() => {
        if(ls.get("STAL_USER")){
            dispatch(saveCurrentUser(ls.get("STAL_USER")));

            const currentTimeStamp = Date.now();
            const expirationDate = ls.get("STAL_USER").exp * 1000;

            if(currentTimeStamp > expirationDate){
                ls.remove("STAL_USER");
                ls.remove("GOOGLE_ACCESS_TOKEN");
                dispatch(removeCurrentUser());
                dispatch(isLogged(false));
                navigate('/login')
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="App">

            {isActiveError &&
                <Error
                    showError={isActiveError}
                    handleClose={handleClose}
                    error={errorMessage}
                />
            }
            {isLoggedIn &&
                <NavBar/>
            }

            <Navigation/>
        </div>
    );
}

export default App;
