import React from 'react';
import {Button} from "react-bootstrap";
const CustomButton = ({callBack, variant = null, text, style, size, className = null}) => {

    return (
        <>
            <Button className={className || ''} variant={variant || ''} onClick={callBack} style={{...style}} size={size}>
                {text}
            </Button>
        </>
    );
};

export default CustomButton;
