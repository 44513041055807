import React, {useEffect, useState} from 'react';
import Input from "../../../generalsComponents/Html/Input";
import requestApi from "../../../../requestApi";
import CustomToast from "../../../generalsComponents/CustomComponents/CustomToast";
import {useDispatch} from "react-redux";
import {setErrorMessage, setIsActive} from "../../../../redux/reducer/errorReducer";
import CustomTable from "../../../generalsComponents/CustomComponents/CustomTable";
import CustomButton from "../../../generalsComponents/CustomComponents/CustomButton";
import {Link, useNavigate} from "react-router-dom";
import {useParams} from "react-router-dom";
import styles from "../../../../styles/Button.module.css"

const ThematicCreation = () => {

    const [thematic, setThematic] = useState('');
    const [thematicId, setThematicId] = useState(null);
    const [datas, setDatas] = useState([]);
    const [show, setShow] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);

    const dispatch = useDispatch();
    const { id } = useParams();
    const values = {name: thematic, characteristics: datas};

    const navigation = useNavigate();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async() => {
        setDatas(datas.concat({id: 0}));

        const thematicIdToInt = parseInt({id}.id);

        if(Number.isInteger(thematicIdToInt))
        {
            setThematicId(thematicIdToInt)
            await loadThematic({id}.id);
            setIsUpdate(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const loadThematic = async (id) => {

        const res = await requestApi(`thematics/${id}`, 'GET');

        if (!res.status.toString().startsWith('20')){
            dispatch(setIsActive(true));
            dispatch(setErrorMessage(res.detail));
            return;
        }

        res.characteristics.map(characteristic => {
            characteristic.isBack = characteristic.isBack  ? '1' : '0';
            characteristic.isRequiredImmo = characteristic.isRequiredImmo  ? '1' : '0';
            characteristic.isGenerate = true;
            return characteristic;
        });

        setDatas(res.characteristics);
        setThematic(res.name);
    }

    const executeAddRow = () => {
        setDatas(datas.concat({id: datas.length}));
    }

    const OnPressPageDownAddRow = (e) => {
        if (e.key === "PageDown") executeAddRow();
    }

    const insertThematic = async () => {

        values.characteristics.map((characteristic) => {
            /* eslint-disable */
            characteristic.isBack = characteristic.isBack == '1';
            /* eslint-disable */
            characteristic.isRequiredImmo = characteristic.isRequiredImmo == '1';
            return characteristic;
        });

        values.characteristics = values.characteristics.filter(el => el.name);

        const res = isUpdate
            ? await requestApi(`thematics/${thematicId}`, 'PUT', values)
            : await requestApi('thematics', 'POST', values)
        ;

        if (!res.status.toString().startsWith('20')){
            dispatch(setIsActive(true));
            dispatch(setErrorMessage(res.detail));
            return;
        }

        setShow(true);
        setTimeout(() => navigation('/thematic-management'), 2000);
    }


    const addRow = () => {
        executeAddRow();
    }

    const plus =
        <Link to='/thematic-creation' style={{ textDecoration: 'none', color:'black'}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" fill="currentColor"
                 className="bi bi-plus-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
            </svg>
        </Link>
    ;

    const reload =
            <span>
                <svg
                    xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                     className="bi bi-arrow-clockwise mx-1" viewBox="0 0 16 16">
                    <path  d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/>
                    <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>
                </svg>
                  Enregistrer
            </span>
    ;

    return (
        <div className="container">
            <h1 className='pt-5'>{isUpdate ? 'Modification ' : 'Création ' }</h1>
            <div className="text-center pt-3">
                <div className="pt-2" style={{marginBottom: '20px'}}>
                    <Input
                        className={styles.input__thematic}
                        placeholder='Nom de la thématique'
                        onChangeSetData={(e) => setThematic(e.target.value)}
                        value={thematic}
                        id='thematic-name'
                    />
                </div>

                <CustomTable
                    datas={datas}
                    setList={setDatas}
                    addRow={OnPressPageDownAddRow}
                    isCreation={true}
                />

                <div className="float-start">
                    <CustomButton
                        callBack={addRow}
                        variant="outline"
                        text={plus}
                    />
                </div>

                <div className="d-flex justify-content-end">
                    {isUpdate
                        ?
                            <CustomToast
                                classNameCustomButton={styles.thematic__characteristics}
                                buttonText={reload}
                                successMessage="thématique Modifiée"
                                handleClick={insertThematic}
                                show={show}
                                setShow={setShow}
                            />
                        :
                            <CustomToast
                                classNameCustomButton={styles.add__creation__thematic}
                                buttonText="Créer une nouvelle Thématique"
                                successMessage="Nouvelle thématique crée"
                                buttonVariant="primary"
                                handleClick={insertThematic}
                                show={show}
                                setShow={setShow}
                            />
                    }
                </div>
            </div>
        </div>
    );
};

export default ThematicCreation;
