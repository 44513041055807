import React, {useState} from 'react';
import {Button, Container, Nav, Navbar, Offcanvas, Stack} from "react-bootstrap";
import LocalStorage from "../utils/LocalStorage";
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import {isLogged, removeCurrentUser} from "../../redux/reducer/userReducer";
import {GOOGLE_CONF} from "../../lib/GoogleLogin";
import {GoogleLogout} from "react-google-login";
import styles from "../../styles/Button.module.css";

const NavBar = () => {

    const ls = new LocalStorage();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const stalUser  = useSelector(state => state.userReducer.user);
    const [isActive, setIsActive] = useState(false);
    const isLeadCoord = stalUser.lead?.name === 'Coordination';

    const logout = () => {
        ls.clear();
        dispatch(removeCurrentUser());
        dispatch(isLogged(false));
        navigate('/login');
    }

    return (
        <Navbar bg="white" expand={false}>
            <Container fluid>
                <Navbar.Brand>
                    <Link className="text-decoration-none" style={{color: '#41464b'}} to="/generate">
                        <img className={styles.logo}  src="/img/SF_Helper_Logo.svg" alt="sf_helper_logo"/>
                    </Link>
                </Navbar.Brand>

                <Stack direction="horizontal" gap={2}>

                    <a
                        rel="noreferrer"
                        className="text-decoration-none"
                        target="_blank"
                        href="https://docs.google.com/document/d/17UTcga79MHOIoHQKeKuoq0XjjgeDAKo7I13IwEO82UM/edit?usp=sharing"
                    >
                        <Button className={styles.insert__button} variant='primary'>Documentation</Button>
                    </a>

                    <GoogleLogout
                        clientId={GOOGLE_CONF.googleClientId}
                        buttonText="Déconnexion"
                        onLogoutSuccess={logout}
                        render={renderProps => (
                            <Button className={styles.logout__button} onClick={renderProps.onClick} disabled={renderProps.disabled} variant='danger'>
                                    Déconnexion
                            </Button>

                        )}
                    />
                    { isLeadCoord &&
                        <>
                            <Navbar.Toggle aria-controls="offcanvasNavbar" onClick={() => setIsActive(true)}/>
                            <Navbar.Offcanvas
                                id="offcanvasNavbar"
                                aria-labelledby="offcanvasNavbarLabel"
                                placement="end"
                                show={isActive}
                            >

                                <Offcanvas.Body>

                                    <Nav  className="justify-content-end flex-grow-1 pe-3" onClick={() => setIsActive(!isActive)}>
                                        <Link to="/generate" className="text-decoration-none text-black" style={{fontSize: '1.5rem'}}>
                                            Accueil
                                        </Link>
                                    </Nav>

                                    <hr>

                                    </hr>

                                    <Nav  className="justify-content-end flex-grow-1 pe-3" onClick={() => setIsActive(!isActive)}>
                                        <Link to="/thematic-management" className="text-decoration-none text-black" style={{fontSize: '1.5rem'}}>
                                            Gestion des thématiques
                                        </Link>
                                    </Nav>

                                    <hr>

                                    </hr>

                                </Offcanvas.Body>
                            </Navbar.Offcanvas>

                        </>

                    }
                </Stack>
            </Container>
        </Navbar>
    );

};

export default NavBar;

