import React from 'react';
import {Modal} from "react-bootstrap";

const Error = ({showError,handleClose,error}) => {
    return (
        <div className='container'>
            <Modal show={showError} onHide={handleClose} className="text-danger text-center" centered>
                <Modal.Header closeButton>
                    <Modal.Title>{error}</Modal.Title>
                </Modal.Header>
            </Modal>
        </div>
    );
};

export default Error;
