import React, {useEffect, useState} from "react";
import LocalStorage from "../../utils/LocalStorage";
import CustomButton from "../../generalsComponents/CustomComponents/CustomButton";
import CustomToast from "../../generalsComponents/CustomComponents/CustomToast";
import CategoryInfo from "./CategoryInfo";
import {CATEGORY_INFO} from "../../utils/const";
import {setErrorMessage, setIsActive} from "../../../redux/reducer/errorReducer";
import {useDispatch} from "react-redux";
import CustomTable from "../../generalsComponents/CustomComponents/CustomTable";
import requestApi from "../../../requestApi";
import Loader from "../../generalsComponents/Loader";
import styles from "../../../styles/Button.module.css"

const CharacteristicTable = () => {

    const ls = new LocalStorage();
    const dispatch = useDispatch();
    const LsMinimumDataStored = 4;

    const [datas, setDatas] = useState([]);
    const [thematics, setThematics] = useState([]);
    const [categoryInfos, setCategoryInfos] = useState([]);
    const [datasJson, setDatasJson] = useState('');
    const [show, setShow] = useState(false);
    const [canGenerate, setCanGenerate] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isNewRow, setIsNewRow] = useState(false);

    useEffect(() => {

        if (localStorage.length > LsMinimumDataStored){
            setDatasIfDatasAreAlreadyStored();
            return;
        }

        setDatas(datas.concat({id: 0}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        setIsLoading(true);
        await fetchThematic();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(() => {
        navigator.clipboard.writeText(datasJson);
    }, [datasJson]);

    useEffect(() => {

        if(datas.length > 0 ){
            displayJsonGenerationButton();
        } else {
            setCanGenerate(false);
        }

        setIsNewRow(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [datas]);

    const fetchThematic = async () => {

        const res = await requestApi('thematics', 'GET');
        if (res.status.toString().startsWith('20')){
            let thematicsFetched = Object.values(res);
            thematicsFetched = thematicsFetched.filter(el => typeof el !== 'number');
            setThematics(thematicsFetched);
            setIsLoading(false);
            return;
        }

        dispatch(setIsActive(true));
        dispatch(setErrorMessage(res.detail));
    }

    const displayJsonGenerationButton = () => {
        // eslint-disable-next-line array-callback-return
        datas.map((data) => {
            if (data.name !== undefined){
                setCanGenerate(true);
            }
        })
    }

    const setDatasIfDatasAreAlreadyStored = () => {

        let storedDatas = [];
        const keys = Object.keys(localStorage).map(el => parseInt(el));
        keys.sort();

        for(let i = 0 ; i < localStorage.length; i++){
            const key = keys[i];
            if(key >= 0){
                storedDatas.push(ls.get(key));
            }
        }
        setDatas(storedDatas.sort((a,b) => a.id - b.id));
    }

    const updateCategoryInfos = () => {
        setCategoryInfos(ls.get(CATEGORY_INFO));
    }

    const executeAddRow = () => {

        if(localStorage.length > LsMinimumDataStored){
            let lastItem = datas.slice(-1)[0];
            setDatas(datas.concat({id: lastItem.id + 1}));
            setIsNewRow(true);
            return;
        }

        setDatas(datas.concat({id: datas.length}));
        setIsNewRow(true);
    }

    const addRow = () => {
        executeAddRow();
    }

    const OnPressPageDownAddRow = (e) => {
        if (e.key === "PageDown") executeAddRow();
    }

    const generateJson = () => {
        let json = [categoryInfos];
        let filteredDatas = datas.filter(el => el.name && el.isGenerate);
        json.push(filteredDatas);

        let dataJson = JSON.stringify(json);
        setDatasJson(dataJson);
    }

    const handleClick = () => {
        if(canGenerate){
            generateJson();
            setShow(true);
        }
    }

    const handleGenerateClick = async () => {

        let parsedDatas;

        try{
            const json = await navigator.clipboard.readText();
            parsedDatas = JSON.parse(json);
        } catch(err){
            dispatch(setIsActive(true));
            dispatch(setErrorMessage('Veuillez vérifier que le texte copié est bien au format JSON'));

            return;
        }

        ls.clearDatas();

        setDatas([]);

        if (parsedDatas.length > 0){
            savePastedJson(parsedDatas);
        }
    }

    const savePastedJson = (parsedDatas) => {

        const res = parsedDatas[1].map(data => {
            ls.add(data.id, data);
            return data;
        });

        ls.add(CATEGORY_INFO, parsedDatas[0]);

        setDatas(res);
        setCategoryInfos(parsedDatas[0]);
    }

    const insertThematicsAndCharacteristics = async (thematicId) => {

        const res = await requestApi(`thematics/${thematicId}`, 'GET');

        if (res.status.toString().startsWith('20')){
            setDatas([]);
            saveCharacteristic(res);
            return;
        }

        dispatch(setIsActive(true));
        dispatch(setErrorMessage(res.detail));

    }

    const saveCharacteristic = (res) => {
        ls.clearDatas();
        res.characteristics.map(characteristic => {
            characteristic.isBack = characteristic.isBack  ? '1' : '0';
            characteristic.isRequiredImmo = characteristic.isRequiredImmo  ? '1' : '0';
            characteristic.isGenerate = true;
            ls.add(characteristic.id, characteristic)
            return characteristic;
        });

        setDatas(res.characteristics);
    }

    const removeAllDatas = () => {
        ls.clearDatas();
        setDatas([{id: 0}]);
    }

    const svg =
        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" fill="currentColor"
             className="bi bi-plus-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
        </svg>
    ;

    const reload = (thematic) => {
        return (
            <span className='d-flex'>
                <svg
                    xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                    className="bi bi-arrow-clockwise mx-1" viewBox="0 0 16 16">
                    <path  d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/>
                    <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>
                </svg>
                {thematic}
            </span>
        );

    }

    return (
        <>
            <div className="float-start w-100">
                <div className="w-100 p-2" style={{backgroundColor: 'white'}} >
                    <CategoryInfo  getCategoryInfos={updateCategoryInfos} currentCategoryInfo={categoryInfos} />
                </div>
                <div className="w-100 p-2 mt-4 mb-4" style={{backgroundColor: 'white'}} >
                    <div className='d-flex mx-3'>
                        {!isLoading
                            ? thematics.length > 0 &&
                            thematics.map(thematic => (
                                <CustomButton
                                    className={styles.Button__thematic}
                                    text={reload(thematic.name) }
                                    variant='dark'
                                    callBack={() => insertThematicsAndCharacteristics(thematic.id)}
                                    key={thematic.id}
                                    size='sm'
                                />))
                            : <Loader isLoading={isLoading} size={12}/>
                        }
                    </div>
                </div>
            </div>

            <CustomTable
                datas={datas}
                setList={setDatas}
                addRow={OnPressPageDownAddRow}
                isCreation={false}
                isNewRow={isNewRow}
            />

            <div className="float-start">
                <CustomButton callBack={addRow} variant="outline" text={svg}/>
            </div>

            <div className='float-end mx-2'>
                <CustomButton
                    callBack={removeAllDatas}
                    text='Tout effacer'
                    variant='outline-danger'
                />
            </div>

            <div className="d-flex justify-content-evenly" style={{margin:"0 37%"}}>
                <CustomToast
                    classNameCustomButton={styles.generate__button}
                    buttonText="Générer le code <>"
                    successMessage="Le code à bien été récupéré !"
                    buttonVariant="danger"
                    handleClick={handleClick}
                    show={show}
                    setShow={setShow}
                />

                <CustomButton
                    className={styles.insert__button}
                    callBack={handleGenerateClick}
                    text='Insérer le code'
                    variant='primary'
                />

            </div>
        </>
    );
}

export default CharacteristicTable;
