import React, {useEffect, useState} from 'react';
import CustomButton from "../../../generalsComponents/CustomComponents/CustomButton";
import {Link, useNavigate} from "react-router-dom";
import requestApi from "../../../../requestApi";
import {setErrorMessage, setIsActive} from "../../../../redux/reducer/errorReducer";
import {useDispatch} from "react-redux";
import Loader from "../../../generalsComponents/Loader";
import {Button, Modal, Stack} from "react-bootstrap";
import styles from "../../../../styles/Button.module.css"

const ThematicManagement = () => {

    const [datas, setDatas] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [idToDelete, setIdToDelete] = useState(0);
    const dispatch = useDispatch();

    const handleClose = () => setShow(false);
    const navigation = useNavigate();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        setIsLoading(true)
        await fetchThematic();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const fetchThematic = async () => {
        const res = await requestApi('thematics', 'GET');
        if (res.status.toString().startsWith('20')){
            let thematicsFetched = Object.values(res);
            thematicsFetched = thematicsFetched.filter(el => typeof el !== 'number');
            setDatas(thematicsFetched);
            setIsLoading(false);
            return;
        }

        dispatch(setIsActive(true));
        dispatch(setErrorMessage(res.detail));
    }

    const modalClick = (id) => {
        setIdToDelete(id);
        setShow(true);
    }

    const removeThematics = async (id) => {
        const res = await requestApi(`thematics/${id}`, 'DELETE');


        if (res.status.toString().startsWith('20')){
            setDatas(datas.filter(el => el.id !== id));
            setShow(false);
            return;
        }

        dispatch(setIsActive(true));
        dispatch(setErrorMessage(res.detail));
    }

    const svg =
            <div onClick={() => navigation('/thematic-creation')}>
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                     className="bi bi-plus-circle" viewBox="0 0 16 16" style={{marginRight: '6px'}}>
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                </svg>
                   Nouvelle thématique
            </div>
    ;

    return (
        <>
            <div className="float-start" >
                <CustomButton
                    className={styles.add__thematic}
                    variant="danger"
                    text={svg} />
            </div>
            <div className="container pt-5 text-center">
                <h1>
                    Interface de gestion des thématiques
                </h1>
            </div>

            <div className='d-flex justify-content-center'>
                {show &&
                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>SF Helper</Modal.Title>
                        </Modal.Header>
                            <Modal.Body>Voulez vous vraiment supprimer l'intégralité de cette thématique ?</Modal.Body>
                        <Modal.Footer>
                            <Button className={styles.pop__cancel} variant="primary" onClick={handleClose}>
                                Annuler
                            </Button>
                            <Button className={styles.pop__delete} variant="danger" onClick={() => removeThematics(idToDelete)}>
                                Supprimer
                            </Button>
                        </Modal.Footer>
                    </Modal>
                }
                <div style={{margin: '50px 0px'}}>
                    {!isLoading
                        ? datas.length > 0
                            ? datas.map(data => (
                                <div className='pt-4' key={data.id}>
                                    <Stack direction="horizontal" gap={3}>
                                        <Link to={`/thematic/${data.id}`} className="text-decoration-none">
                                            <CustomButton
                                                className={styles.admin__button}
                                                variant='primary'
                                                text={data.name}
                                                style={{ width: '13rem', justifyContent: 'center' }}
                                            />
                                        </Link>
                                        <Button  variant="outline-danger"  onClick={() => modalClick(data.id)}>
                                            <svg  xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                                <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                            </svg>
                                        </Button>
                                    </Stack>
                                </div>
                            ))
                            : <h5>Aucune thématique crée</h5>
                        : <div className='pt-5'>
                            <Loader isLoading={isLoading} size={15}/>
                        </div>
                    }
                </div>
            </div>
        </>

    );
};

export default ThematicManagement;
