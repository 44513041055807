import React, {useEffect, useState} from 'react';
import Select from "../../generalsComponents/Html/Select";
import {numbers} from "../../utils/arrays";
import LocalStorage from "../../utils/LocalStorage";
import {CATEGORY_INFO} from "../../utils/const";

const CategoryInfo = ({ getCategoryInfos, currentCategoryInfo }) => {

    const ls = new LocalStorage();
    const currentRow = ls.get(CATEGORY_INFO);

    const [imagesNumber, setImagesNumber] = useState(currentRow?.imagesNumber || 5);
    const [filesNumber, setFilesNumber] = useState(currentRow?.filesNumber || 0);

    const values = {imagesNumber, filesNumber};

    useEffect(() => {

        ls.add(CATEGORY_INFO, values);
        getCategoryInfos(values);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imagesNumber, filesNumber]);

    return (
        <>
            <h4 className='float-start mx-4 mt-1'>Information des catégories</h4>
            <div className='d-flex pt-1 mx-1 w-100'>
                <div style={{margin: '0px 20px'}}>
                    <p>Nombre d'images</p>
                    <Select
                        datas={numbers}
                        name="image-number"
                        className='form-select-sm'
                        value={currentCategoryInfo.imagesNumber || imagesNumber}
                        onChangeSetData={(e) => setImagesNumber(e.target.value)}
                    />
                </div>

                <div className='text-center' style={{margin: '0 20px'}}>
                    <p>Nombre de fichiers PDF</p>
                    <Select
                        datas={numbers}
                        name="image-number"
                        className='form-select-sm'
                        value={currentCategoryInfo.filesNumber || filesNumber}
                        onChangeSetData={(e) => setFilesNumber(e.target.value)}
                    />
                </div>
            </div>
        </>

    );
};

export default CategoryInfo;
