import React, {useEffect, useState} from "react";
import Input from "./Html/Input";
import Select from "./Html/Select";
import {
    backDatas,
    columnToExcludePanel,
    columnToExcludeSourceOrLongText,
    requiredImmoDatas,
    sortsDatas,
    typesDatas,
    typoDatas
} from "../utils/arrays";
import LocalStorage from "../utils/LocalStorage";
import Delete from "./Delete";
import { MdDragHandle } from "react-icons/md";

const Row = ({ getDatas, id, remove, fetchedCharacteristic, isNewRow,  creation = false }) => {

    const ls = new LocalStorage();
    let currentRow;

    if(!creation){
        currentRow = ls.get(id);
    }

    const [name, setName] = useState(fetchedCharacteristic.name || currentRow?.name || "");
    const [type, setType] = useState(fetchedCharacteristic.type || currentRow?.type || "varchar");
    const [suffix, setSuffix] = useState(fetchedCharacteristic.suffix || currentRow?.suffix || "");
    const [sort, setSort] = useState(fetchedCharacteristic.sort || currentRow?.sort || "0");
    const [typology, setTypology] = useState(fetchedCharacteristic.typology || currentRow?.typology || "1");
    const [isBack, setIsBack] = useState(fetchedCharacteristic.isBack || currentRow?.isBack || '0');
    const [isRequiredImmo, setIsRequiredImmo] = useState(fetchedCharacteristic.isRequiredImmo || currentRow?.isRequiredImmo || "0");
    const [isGenerate, setIsGenerate] = useState(currentRow?.isGenerate || true);

    useEffect(() => {

        if(values.name !== ''){
            getDatas(values);
            if(!creation){
               return ls.add(id, values)
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name, typology, type, suffix, sort, isBack, isRequiredImmo, isGenerate]);

    useEffect(() => {
        columnsToExclude();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type]);

    const values = creation
        ? {id ,name, typology, type, suffix, sort, isBack, isRequiredImmo}
        : {id ,name, typology, type, suffix, sort,isBack, isRequiredImmo, isGenerate}
    ;

    const columnsToExclude = () => {

        if (columnToExcludePanel.includes(values.type)){
            setSuffix("");
            setSort("");
            setTypology("");
            setIsRequiredImmo("0");
        }

        if (columnToExcludeSourceOrLongText.includes(values.type)){
            setSuffix("");
            setSort("");
            setTypology("");
            setIsRequiredImmo("0");
            setIsBack("0");
        }
    }

    return (
        <tr>
            <td>
                <MdDragHandle/>
            </td>

            <td>
                <Input
                    type="text"
                    id="name"
                    onChangeSetData={(e) => setName(e.target.value)}
                    className="input-group-text"
                    value={name}
                    isNewRow={isNewRow}
                />
            </td>
            <td>
                <Select
                    id="type"
                    name="type"
                    datas={typesDatas}
                    onChangeSetData={(e) => setType(e.target.value)}
                    value={type}
                />
            </td>
            {columnToExcludePanel.includes(values.type)
                ? <>
                    <td> </td>
                    <td> </td>
                    <td> </td>
                    <td> </td>
                    </>
                : <>
                    <td>
                        <Input
                            type="text"
                            id="suffixe"
                            onChangeSetData={(e) =>setSuffix(e.target.value)}
                            className="input-group-text"
                            value={suffix}
                        />
                    </td>

                    <td>
                        <Select
                            id="sorts"
                            name="sorts"
                            datas={sortsDatas}
                            onChangeSetData={(e) =>setSort(e.target.value)}
                            value={sort}
                        />
                    </td>

                    <td>
                        <Select
                            id="typo"
                            name="typo"
                            datas={typoDatas}
                            onChangeSetData={(e) =>setTypology(e.target.value)}
                            value={typology}
                        />
                    </td>

                    <td>
                        <Select
                            id="required-immo"
                            name="required-immo"
                            datas={requiredImmoDatas}
                            onChangeSetData={(e) => setIsRequiredImmo(e.target.value)}
                            value={isRequiredImmo}
                        />
                    </td>
                </>

            }

            {columnToExcludeSourceOrLongText.includes(values.type)
                ? <td> </td>
                : <td>
                    <Select
                        id="back"
                        name="back"
                        datas={backDatas}
                        onChangeSetData={(e) => setIsBack(e.target.value)}
                        value={isBack}
                    />
                </td>
            }

            {!creation &&
                <td>
                    <div className="text-center pt-1">
                        <Input
                            type="checkbox"
                            id="generate"
                            className="form-check-input"
                            onChangeSetData={() => setIsGenerate(!isGenerate)}
                            value={isGenerate ? "Oui" : "Non"}/>
                    </div>
                </td>
            }

            <td>
                <Delete remove={remove}/>
            </td>
        </tr>
    );
}

export default Row;
